<template>
   <div>

      <Header textColor="white" />

      <section id="home" class="relative z-20">
          <div class="mt-28 lg:mt-40">
              <div class="container mx-auto relative z-20">
                  <img src="../assets/coffee-man.png" alt="Barista pouring coffee" class="absolute top-0 z-0 hidden lg:block max-w-6xl xl:max-w-none lg:-mt-40" style="right:-50%" />
                  <div class="relative flex flex-col lg:flex-row items-center lg:items-start z-10 bg-gray-background lg:bg-transparent">
                      <div class="lg:w-1/3 bg-white lg:mx-40 shadow-focus text-black rounded-lg">
                          <div class="p-8 lg:p-12">
                              <span v-if="isShopMode">
                                <h1 class="font-black text-5xl leading-none">Promoting a coffee shop made easy!</h1>
                                <h2 class="text-xl pt-8">Attract more customers, advertise offers and specials, run loyalty programs in <a href="https://www.loffeemobileapp.com" class="text-pink hover:underline">Loffee App</a>.</h2>
                                <a href="/portal/register" class="inline-block rounded-full bg-pink text-white px-10 py-3 cursor-pointer mt-6 lg:mt-8 font-bold text-lg">Register my shop</a>
                              </span>
                              <span v-else>
                                <h1 class="font-black text-5xl leading-none">Your coffee loyalty cards online!</h1>
                                <h2 class="text-xl pt-8">Find the best offers and coffee shops near you and get your virtual stamps!</h2>                                  
                                <div class="mt-4 flex flex-row">
                                    <a target="_blank" href="https://apps.apple.com/au/app/loffee/id1534669066" class="flex-1">
                                        <img src="../assets/appstore.png" alt="iOS app store" />
                                    </a>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=au.com.loffee.lofee" class="flex-1">
                                        <img src="../assets/googleplay.png" alt="Google Play Store" />
                                    </a>
                                </div>
                                <a href="https://www.loffee.com.au" class="block lg:hidden text-lg text-pink font-bold mt-6">I’m a Coffee Shop owner</a>
                              </span>
                          </div>
                      </div>
                      <div class="flex-1 mb-10 lg:mb-0 mt-48 mx-auto lg:ml-0">
                          <div class="relative">                        
                              <img src="../assets/safe-hygenic.svg" alt="Safe and Hygenic" class="absolute top-0 left-0 -mx-16 lg:-mx-32 -mt-12 lg:mt-32 z-0 w-24 lg:w-auto" />
                              <div class="bg-teal-300 p-2 rounded-xl inline-block z-1 relative shadow-phone">
                                  <div class="bg-gray-800 p-2 rounded-xl">
                                      <img src="../assets/screen-shop.png" alt="Screenshot of shop view" />
                                  </div>
                                  <div class="flex justify-center">
                                      <img src="../assets/cube.png" alt="Cube" class="absolute top-0 mx-auto -mt-32" />
                                  </div>
                              </div>                        
                          </div>
                      </div>
                  </div>
              </div>
              <div class="bg-gradient-to-r from-lightpink to-darkpink lg:pl-10 xl:pl-40 lg:mr-24 lg:-mt-20 lg:rounded-r-lg z-10 relative shadow-focus lg:shadow-none">
                  <div class="container mx-auto flex flex-col-reverse lg:flex-row items-start ">
                      <div class="flex-1 self-end mt-12 lg:mt-96 px-4 lg:px-0">
                          <div class="bg-teal-300 p-2 rounded-xl inline-block shadow-phone">
                              <div class="bg-gray-800 p-2 rounded-xl">
                                  <img src="../assets/screen-map.png" alt="Screenshot of map view" />
                              </div>
                          </div>
                      </div>
                      <div class="flex-1 mr-2 lg:mr-0 mt-20 lg:mt-56 ml-0 lg:ml-6 px-4 lg:px-0">
                          <div class="bg-gray-400 p-2 rounded-xl inline-block shadow-phone">
                              <div class="bg-gray-800 p-2 rounded-xl">
                                  <img src="../assets/screen-stamp.png" alt="Screenshot of stamp received view" />
                              </div>
                          </div>
                      </div>
                      <div class="lg:w-1/2 xl:w-1/3 bg-white shadow-xl text-black p-8 lg:p-12 lg:mt-48 lg:mx-24 lg:-mb-20 lg:rounded-lg text-center lg:text-left">
                        <span v-if="isShopMode">
                          <p class="font-black text-5xl leading-none">Do you <img class="block mx-auto lg:mx-0 py-2" src="../assets/logo.svg" alt="Logo" /> a morning coffee?</p>
                          <p class="text-2xl leading-tight pt-4 pb-10 font-bold">How does a loyalty program work?</p>
                          <ul class="list-decimal text-left text-xl pl-4">
                              <li>User registers in the Loffee App.</li>
                              <li>User checks which coffee shops are around or have attractive promos.</li>
                              <li>User goes to your coffee shop and buys coffee.</li>
                              <li class="font-bold">Cashier uses Loffee Stamp to digitally stamp the virtual stamp card.</li>
                          </ul>
                        </span>
                        <span v-else>
                            <p class="font-black text-5xl leading-none">Be among thousands of aussies using <img class="block mx-auto lg:inline-block py-2 lg:py-0" src="../assets/logo.svg" alt="Logo" /></p>
                            <p class="text-xl pt-8">No more plastic loyalty cards in your wallet! Search the hot offers around you and stash your rewards digitally!</p>
                        </span>
                      </div> 
                  </div>                       
              </div>
              <div v-if="isShopMode" class="-mt-20">
                  <div class="bg-gray-background pt-48 lg:ml-24 shadow-focus lg:shadow-none overflow-hidden">
                      <div class="container mx-auto flex flex-col lg:flex-row items-end">
                          <div class="lg:w-1/2 xl:w-1/3 bg-white lg:ml-12 shadow-xl text-black p-8 lg:p-12 rounded-t-lg text-xl">
                              <p class="font-black text-5xl leading-none">How can I join?</p>
                              <p class="mt-10">Easy! Register your coffee shop by clicking on the button below.</p>
                              <p class="mt-10">After registered, fill in your shop's details, and a FREE Loffee Stamp will be sent to you via post.</p>
                              <p class="mt-10 font-bold">Activate your Stamp once you've received it and you are done!</p>
                              <a href="/portal/register" class="inline-block rounded-full bg-pink text-white px-10 py-3 cursor-pointer mt-10 font-bold text-lg mb-2">Register my shop</a>
                          </div>
                          <div>
                              <img src="../assets/kit.png" alt="Loffee kit" class="p-10 lg:p-20" />
                          </div>
                      </div>
                  </div>
              </div>
              <div class="lg:mt-24 rounded-r-lg" :class="{'lg:mr-48 lg:bg-gradient-to-r lg:from-darkpink lg:to-lightpink': isShopMode}">
                  <div class="container mx-auto flex flex-col-reverse items-start lg:flex-row" :class="{'lg:flex-row-reverse': !isShopMode}">
                      <div class="flex-1 w-full bg-gradient-to-r from-darkpink to-lightpink lg:bg-none lg:mt-20 lg:ml-40 pt-10 lg:pt-0 pb-10">
                        <div v-if="isShopMode" class="w-full lg:w-2/3 xl:w-1/2">
                          <div class="inline-block mx-2">
                            <div @click="showVideoModal('D5nysJik_vU')" class="inline-block border-2 border-dashed border-white rounded-lg cursor-pointer relative">
                                <img class="absolute w-16 h-16 -ml-8 -mt-8" src="../assets/youtube-button.svg" alt="YouTube play button" style="left: 50%; top: 50%;" />
                                <img class="rounded-lg" src="https://img.youtube.com/vi/D5nysJik_vU/hqdefault.jpg" alt="Loffee Portal video screenshot" />
                            </div>
                            <div class="text-left">Loffee Portal</div>
                          </div>
                        </div>                      
                        <div class="w-full" :class="{'mt-10 lg:w-2/3 xl:w-1/2 lg:float-right': isShopMode}">
                          <div class="inline-block mx-2">
                            <div @click="showVideoModal('nZ9IgxyH11M')" class="inline-block border-2 border-dashed border-white rounded-lg cursor-pointer relative">
                                <img class="absolute w-16 h-16 -ml-8 -mt-8" src="../assets/youtube-button.svg" alt="YouTube play button" style="left: 50%; top: 50%;" />                            
                                <img class="rounded-lg" src="https://img.youtube.com/vi/nZ9IgxyH11M/hqdefault.jpg" alt="Loffee App video screenshot" />
                            </div>
                            <div v-if="isShopMode" class="text-left">Loffee App</div>
                          </div>
                        </div>
                      </div>
                      <div class="lg:w-1/2 xl:w-1/3 bg-white lg:ml-10 lg:mt-40 shadow-focus text-black p-8 lg:p-12 rounded-lg lg:-mr-20 lg:mb-10">
                          <p class="font-black text-5xl leading-none">Show me how it works!</p>
                          <p v-if="isShopMode" class="text-xl leading-tight pt-6 pb-10">Check the videos for instructions on how to set up your shop's profile, see how customers will find and view it and learn how easy it is to use the Loffee Stamp!</p>
                          <p v-else class="text-xl leading-tight pt-6 pb-10">Check out the video on how to use the Loffee App and how easy it is to get stamps and be rewarded!</p>
                      </div>
                  </div>
              </div> 
          </div>       
      </section>

      <section v-if="isShopMode" id="pricing" class="relative z-1">
          <div class="bg-gray-background lg:-mt-40 py-8 lg:pt-40 lg:pb-0">
            <div class="container mx-auto px-2 pt-10 pb-12 text-gray-800">

                <h1 class="w-full my-2 text-5xl font-black leading-tight text-center text-gray-800">Pricing</h1>
                <p class="font-bold text-3xl text-center">Sign up now and don't pay anything for the entire year!</p>
                <p class="text-2xl text-center">You can also opt out at any time</p>
                <div class="w-full mb-4">
                    <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                </div>



                <div class="flex flex-col sm:flex-row justify-center lg:space-x-4 pt-12 my-12 sm:my-4">

                    <div class="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4 shadow-focus pb-4">
                        <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden">
                            <img src="../assets/shop.png" alt="shop illustration" class="w-24 mx-auto mt-10" />
                        </div>
                        <div class="flex-1 mt-auto bg-white overflow-hidden p-8">
                            <div class="w-full text-3xl font-bold text-center leading-none">1 Shop</div>
                            <div class="w-full pt-6 text-3xl font-thin text-center leading-none">$30/month</div>
                            <div class="flex items-center justify-center leading-none">
                                <a href="/portal/register?planShopCount=1" class="inline-block rounded-full bg-pink text-white px-10 py-3 cursor-pointer mt-6 lg:mt-6 font-bold text-lg">Choose Plan</a>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4 shadow-focus pb-4">
                        <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden">
                            <div class="mt-10 flex justify-center">
                                <div class="relative">
                                    <img src="../assets/shop.png" alt="shop illustration" class="absolute w-20 mt-4" />
                                    <img src="../assets/shop.png" alt="shop illustration" class="relative w-24 ml-16" />
                                </div>
                            </div>
                        </div>
                        <div class="flex-1 mt-auto bg-white overflow-hidden p-8">
                            <div class="w-full text-3xl font-bold text-center leading-none">2 Shops</div>
                            <div class="w-full pt-6 text-3xl font-thin text-center leading-none">$50/month</div>
                            <div class="flex items-center justify-center leading-none">
                                <a href="/portal/register?planShopCount=2" class="inline-block rounded-full bg-pink text-white px-10 py-3 cursor-pointer mt-6 lg:mt-6 font-bold text-lg">Choose Plan</a>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4 shadow-focus pb-4">
                        <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden">
                            <div class="mt-10 flex justify-center">
                                <div class="relative">
                                    <img src="../assets/shop.png" alt="shop illustration" class="absolute w-20 -ml-16 mt-4" />
                                    <img src="../assets/shop.png" alt="shop illustration" class="absolute w-20 ml-20 mt-4" />
                                    <img src="../assets/shop.png" alt="shop illustration" class="relative w-24" />
                                </div>
                            </div>
                        </div>
                        <div class="flex-1 mt-auto bg-white overflow-hidden p-8">
                            <div class="w-full text-3xl font-bold text-center leading-none">3+ Shops</div>
                            <div class="w-full pt-6 text-3xl font-thin text-center leading-none">Contact us</div>
                            <div class="flex items-center justify-center leading-none">
                                <a href="/portal/register?planShopCount=999" class="inline-block rounded-full bg-pink text-white px-10 py-3 cursor-pointer mt-6 lg:mt-6 font-bold text-lg">Our Contact</a>
                            </div>
                        </div>
                    </div>                

                </div>

            </div>
          </div>

      </section>

      <section v-if="isShopMode" id="contact-us" class="text-center pt-10 mb-12 -mt-24 lg:mt-0">
          <div class="pt-24 lg:-mt-32 lg:pt-20">
            <div class="lg:bg-gradient-to-r lg:from-lightpink lg:to-darkpink lg:mr-20 lg:pt-6 rounded-r-lg overflow-hidden">
                <div class="container mx-auto lg:flex lg:flex-row lg:items-end">
                    <div class="lg:flex-1 lg:shadow-xl bg-white text-black xl:px-32 lg:pt-20 rounded-t-lg lg:ml-24 lg:mr-6 xl:ml-56 xl:mr-40 lg:mt-20">
                        <ContactUs />
                    </div>
                </div>
            </div>
          </div>
      </section>

      <Modal :showing="videoModalShowing" @close="videoModalShowing=false">
        <div class="relative h-0" style="padding-bottom:56.25%">
            <iframe class="absolute top-0 left-0 w-full h-full rounded-lg" :src="`https://www.youtube.com/embed/${videoId}?autoplay=1`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </Modal>

      <Footer />

    </div>
</template>

<script>
import { SHOP_MODE } from '../config'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ContactUs from '../components/ContactUs'
import Modal from '../components/Modal'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    ContactUs,
    Modal
  },
  data() {
      return {
        videoModalShowing: false,
        videoId: ''
      }
  },
  computed: {
      isShopMode() {
          return SHOP_MODE;
      }
  },
  methods: {
      showVideoModal(videoId) {
          this.videoId = videoId;
          this.videoModalShowing = true;
      }
  }
}
</script>
