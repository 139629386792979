<template>
  <FrameError @error="showSnackbar" :capture=true>
    <div>
      <slot/>
    </div>
  </FrameError>
</template>

<script>
import FrameError from "./FrameError";

export default {
  components: {
    FrameError
  },
  methods: {
    showSnackbar(error) {
      this.$snackbar.showError(error);
    }
  }
};
</script>