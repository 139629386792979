import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import { SHOP_MODE } from './config'
import Home from './pages/Home'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'
import PageNotFound from './pages/PageNotFound'

const router = new VueRouter({
    mode: 'history',
    base: '/',
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
          return savedPosition;
      }
      if (to.hash) {
          return { selector: to.hash }
      }
      return { x: 0, y: 0 }
    },  
    routes: [
      { 
        name: 'home',
        path: '/',
        components: {
          default: Home
        }
      },
      {
        name: 'terms',
        path: SHOP_MODE ? '/terms' : '/termsmobileapp',
        components: {
          default: Terms
        }          
      },
      {
          name: 'privacy',
          path: SHOP_MODE ? '/privacy' : '/privacymobileapp',
          components: {
            default: Privacy
          }
      },       
      { 
        path: '*', 
        components: {
          default: PageNotFound 
        }
      }
    ]
  });

export default router;