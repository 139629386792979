import '../constants.js';
import UiSnackbarContainer from '../components/UiSnackbarContainer.vue'
import { BACKGROUND_COLOR_ERROR, BACKGROUND_COLOR_SUCCESS, BACKGROUND_COLOR_INFO } from '../constants.js';

const Snackbar = {
  install(Vue) {
    this.EventBus = new Vue()

    Vue.component('UiSnackbarContainer', UiSnackbarContainer)

    Vue.prototype.$snackbar = {
      show(params) {
        Snackbar.EventBus.$emit('show', params)
      },
      showError(message) {
        Snackbar.EventBus.$emit('show', { message: message + '', backgroundColor: BACKGROUND_COLOR_ERROR })
      },
      showSuccess(message) {
        Snackbar.EventBus.$emit('show', { message: message + '', backgroundColor: BACKGROUND_COLOR_SUCCESS })
      },
      showInfo(message) {
        Snackbar.EventBus.$emit('show', { message: message + '', backgroundColor: BACKGROUND_COLOR_INFO })
      }
    }
  }
}

export default Snackbar