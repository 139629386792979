import Vue from 'vue'
import router from './router'
import eventBus from './eventBus'
import Snackbar from './plugins/SnackbarPlugin.js'
import './validation'
import { GA_ID } from './config'
import VueGtag from "vue-gtag"
import App from './App.vue'

Vue.config.productionTip = false

Vue.prototype.$eventBus = eventBus

Vue.use(Snackbar)

if (GA_ID) {
  Vue.use(VueGtag, {
    config: { id: GA_ID }
  }, router);
}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
