<template>
    <div class="mb-4">
        <label class="block text-gray-700 text-sm mb-2">{{label}} {{required ? '*' : ''}}</label>
        <ValidationProvider :name="label" :rules="(required ? 'required' : '')" v-slot="{ classes, errors }">
            <input v-if="type !== 'textarea'" :type="type" :value="value" @input="$emit('input', $event.target.value)" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" :class="classes" :placeholder="placeholderText" />
            <textarea v-else rows="6" :value="value" @input="$emit('input', $event.target.value)" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none" :class="classes" :placeholder="placeholderText"></textarea>
            <p class="text-red text-sm italic mt-1">{{ errors[0] }}</p>
        </ValidationProvider>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'text'
        },
        label: {
            type: String,
            required: true
        },
        value: {
            type: String
        },
        required: {
            type: Boolean,
            default: false
        },
        placeholderText: {
            type: String
        }
    }
}
</script>