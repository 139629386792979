<template>
    <transition name="transition-slide" @after-enter="onEnter" @after-leave="onLeave">
        <div class="px-5 py-3 mx-1 mt-1 mb-3 inline-flex items-center pointer-events-auto rounded-lg" :class="backgroundColor" @click="onClick">
            <div class="text-white flex-grow cursor-default leading-normal">
                <slot>{{ message }}</slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        message: String,
        backgroundColor: {
            type: String,
            required: false,
            default: 'bg-info'
        }
    },
    methods: {
        onClick() {
            this.$emit('click');
        },
        onEnter() {
            this.$emit('show');
        },
        onLeave() {
            this.$emit('hide');
        }
    }
};
</script>

<style>
.transition-slide-enter-active,
.transition-slide-leave-active {
    transition: transform 0.4s ease;
}

.transition-slide-enter,
.transition-slide-leave-active {
    transform: translateY(84px);
}

.transition-fade-enter-active,
.transition-fade-leave-active {
    transition: opacity 0.4s ease;
}

.transition-fade-enter,
.transition-fade-leave-active {
    opacity: 0;
}
</style>