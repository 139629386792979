<template>
   <nav id="header" class="fixed w-full z-30 top-0" :class="{'bg-white lg:text-black shadow-menu': stickyNav}">

        <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-8 lg:py-5 lg:pl-48 lg:pr-10 xl:pr-20">

            <div class="pl-4 flex items-center mx-auto">
                <router-link to="/" class="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl">
                    <img alt="Logo" src="../assets/logo.svg" />
                </router-link>
            </div>

            <div v-if="isShopMode" class="relative block lg:hidden pr-4 z-30">
                <span id="nav-toggle" class="navTrigger">
                    <i></i>
                    <i></i>
                    <i></i>
                </span>
            </div>

            <div class="fixed top-0 ml-4 lg:ml-0 h-screen lg:h-auto lg:static w-full flex-grow lg:items-center lg:w-auto hidden lg:block bg-gradient-to-r from-darkpink to-lightpink lg:bg-none pt-24 pl-10 lg:p-0 z-20 text-left shadow-md lg:shadow-none"
                id="nav-content">
                <div class="lg:flex justify-end flex-1 items-center">
                    <ul v-if="isShopMode" id="nav" class="list-reset" :class="linkClasses">
                        <li class="block lg:float-left" :class="{'font-bold lg:text-pink': stickyNav && activeIndex === 0}">
                            <router-link :to="{ name: 'home', hash: '#home' }" data-title="Home" class="inline-block no-underline py-4 lg:py-2 px-4 hoverable text-lg">Home</router-link>                            
                        </li>
                        <li class="block lg:float-left" :class="{'font-bold lg:text-pink': stickyNav && activeIndex === 1}">
                            <router-link :to="{ name: 'home', hash: '#pricing' }" data-title="Pricing" class="inline-block no-underline py-4 lg:py-2 px-4 hoverable text-lg">Pricing</router-link>
                        </li>
                        <li class="block lg:float-left" :class="{'font-bold lg:text-pink': stickyNav && activeIndex === 2}">
                            <router-link :to="{ name: 'home', hash: '#contact-us' }" data-title="Contact us" class="inline-block no-underline py-4 lg:py-2 px-4 hoverable text-lg">Contact us</router-link>
                        </li>
                        <li class="block lg:float-left">
                            <a class="inline-block no-underline py-4 lg:py-2 px-4 text-lg" href="/portal/login" data-title="Login">Login</a>
                        </li>
                        <li class="block lg:float-left">
                            <a class="inline-block no-underline py-4 lg:py-2 px-4 text-lg" href="/portal/register" data-title="Register">Register</a>
                        </li>
                        <hr id="nav_slide_hover"
                            class="invisible rounded-full m-0 border-none h-1 transition-all duration-300 ease-in-out overflow-auto ml-4"
                            :class="{'bg-pink': stickyNav, 'bg-white': !stickyNav, 'lg:visible': activeIndex >= 0}"
                            style="width:5%" />
                    </ul>
                    <ul v-else>
                        <li>
                            <a href="https://www.loffee.com.au" class="inline-block text-pink font-bold bg-white rounded-full py-2 px-6">I’m a Coffee Shop owner</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>    
</template>

<script>
import { SHOP_MODE } from '../config'
import _ from 'lodash'

export default {
    props: {
        textColor: {
            type: String,
            default: 'black'            
        }
    },
    data() {
        return {
            activeIndex: -1,
            stickyNav: false
        }
    },
    computed: {
        linkClasses() {
            return this.stickyNav ? '' : `lg:text-${this.textColor}`;
        },
        isShopMode() {
            return SHOP_MODE;
        }
    },
    mounted() {

        var scrollpos = window.scrollY;

        let mainNavLinks = document.querySelectorAll("nav ul li a.hoverable");

        let activate = new Event("activate", {bubbles: true});

        const self = this;

        function activeSectionCheck() {
            scrollpos = window.scrollY;

            self.stickyNav = scrollpos > 10;

            let fromTop = (window.scrollY + 20);

            mainNavLinks.forEach((link, index) => {

                if (link.hash) {

                    let section = document.querySelector(link.hash);

                    if (
                        section &&
                        section.offsetTop <= fromTop &&
                        section.offsetTop + section.offsetHeight > fromTop
                    ) {
                        self.activeIndex = index;
                        link.dispatchEvent(activate);
                    } else {
                        if (section && index === mainNavLinks.length -1 && section.offsetTop + section.offsetHeight < fromTop) {
                            self.activeIndex = -1;
                        }
                    }
                }
            });            
        }

        document.addEventListener('scroll', _.throttle(activeSectionCheck, 50));

        // Call on page load to activate section (if any)
        activeSectionCheck();

        let menu = document.getElementById('nav');

        if (menu) {
            let menu_slider_hover = document.getElementById('nav_slide_hover');
            if (menu_slider_hover) {
                nav_slider(menu, function (el, width, tempMarginLeft) {
                    var underlineHandler = () => {
                        menu_slider_hover.style.marginLeft = tempMarginLeft + '%';                        
                    };
                    el.addEventListener("mouseover", underlineHandler, false);
                    el.addEventListener("activate", underlineHandler, false);
                });
            }

        }

        function nav_slider(menu, callback) {
            let menu_width = menu.offsetWidth;
            // We only want the <li> </li> tags
            menu = menu.querySelectorAll('li a.hoverable');
            if (menu.length > 0) {
                var marginLeft = [];
                // Loop through nav children i.e li
                [].forEach.call(menu, (el, index) => {
                    // Dynamic width/margin calculation for hr              
                    var width = getPercentage(el.offsetWidth, menu_width);
                    var tempMarginLeft = 0;
                    // We don't want to modify first elements positioning
                    if (index != 0) {
                        tempMarginLeft = getArraySum(marginLeft);
                    }
                    // Set mouse event  hover/click
                    callback(el, width, tempMarginLeft + getPercentage(parseInt(window.getComputedStyle(el, null).getPropertyValue('padding-left')), menu_width));
                    /* We store it in array because the later accumulated value is used for positioning */
                    marginLeft.push(width);
                });
            }
        }

        // Might make this dynamic for px, %, pt, em 
        function getPercentage(min, max) {
            return min / max * 100;
        }

        // Not using reduce, because IE8 doesn't supprt it
        function getArraySum(arr) {
            let sum = 0;
            [].forEach.call(arr, (el) => {
                sum += el;
            });
            return sum;
        }


        var navMenuDiv = document.getElementById("nav-content");
        var navMenu = document.getElementById("nav-toggle");
        
        document.onclick = check;
        function check(e){
            var target = (e && e.target) || (event && event.srcElement);
            
            //Nav Menu
            if (!checkParent(target, navMenuDiv) || target.tagName === "A") {
                // click NOT on the menu
                if (checkParent(target, navMenu)) {
                    // click on the link
                    if (navMenuDiv.classList.contains("hidden")) {
                        navMenuDiv.classList.remove("hidden");
                        if (navMenu) {
                            navMenu.classList.add("active");
                        }
                    } else {
                        navMenuDiv.classList.add("hidden");
                        if (navMenu) {
                            navMenu.classList.remove("active");                                      
                        }
                    }
                } else {
                    // click both outside link and outside menu, hide menu
                    navMenuDiv.classList.add("hidden");
                    if (navMenu) {
                        navMenu.classList.remove("active");
                    }
                }
            }
        
        }
        function checkParent(t, elm) {
            while(t.parentNode) {
                if( t == elm ) {return true;}
                t = t.parentNode;
            }
            return false;
        } 
    }
}
</script>