<template>
    <div>
        <p class="font-bold text-5xl">Contact us</p>
        <p class="text-xl">Leave us a message below.</p>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form ref="form" @submit.prevent="handleSubmit(submitForm)" novalidate class="px-8 pt-6 pb-8 mb-4 text-left">
                <FormInput type="text" label="Full Name" :required="true" placeholder-text="Coffee Lovers" v-model="fullName" />
                <FormInput type="email" label="Email" :required="true" placeholder-text="john@email.com" v-model="email" />
                <FormInput type="textarea" label="Message" :required="true" placeholder-text="Type message" v-model="message" />                        
                <button type="submit" class="rounded-full bg-pink text-white px-10 py-3 cursor-pointer mt-6 lg:mt-16 font-bold text-lg block mx-auto">Send Message</button>
            </form> 
        </ValidationObserver>
    </div>
</template>

<script>
import httpClient from '../httpClient'
import FormInput from './FormInput'

export default {
    components: {
        FormInput
    },
    data() {
        return {
            fullName: '',
            email: '',
            message: ''
        }
    },
    methods: {
        async submitForm() {
            const payload = {
                name: this.fullName,
                email: this.email,
                message: this.message
            };

            try {
                const response = await httpClient({
                    method: 'post',
                    url: '/v1/support/enquiry',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: payload
                });

                if (Boolean(response.data.code) || Boolean(response.data.errors)) {
                    throw new Error(response.data.errors?.[0] || 'An unknown error occurred. Please try again later.');
                }

                this.$snackbar.showSuccess('Message sent');
                this.$refs.form.reset();
            }
            catch (error) {
                this.$snackbar.showError(`Message failed to send. Please try again later. Details: ${error}`);
            }
        }
    }
}
</script>