<template>
    <transition name="fade">
      <div v-if="showing" class="fixed w-full h-full top-0 left-0 z-40 antialiased font-sans overflow-x-hidden">
        <div class="relative min-h-screen flex items-center justify-center">
          <div class="bg-black opacity-75 w-full h-full absolute z-10 inset-0"></div>
          <div class="w-full mx-2 lg:w-2/3 lg:mx-auto fixed inset-x-0 z-50 relative">
            <button class="absolute top-0 right-0 -mt-8 focus:outline-none" @click="$emit('close')">
                <span class="font-bold">Close</span>
                <svg class="inline ml-2" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.28357 1.39941L17.76 17.8759L1.28357 1.39941Z" stroke="#FFFFFF" stroke-width="2"/>
                    <path d="M17.76 1.39941L1.28357 17.8759L17.76 1.39941Z" stroke="#FFFFFF" stroke-width="2"/>
                </svg>                 
            </button>          
            <slot></slot>
          </div>
        </div>
      </div>        
    </transition>
</template>

<script>
export default {
  props: {
    showing: {
      required: true,
      type: Boolean
    },
    wide: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style>
.modal-container {
  max-height: calc(100vh - 100px);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>