import axios from 'axios';
import eventBus from './eventBus';

let config = {
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/api`
};

const httpClient = axios.create(config);

const requestInterceptor = config => {
  eventBus.$emit('request_start', config);
  return config;
};

httpClient.interceptors.request.use(requestInterceptor);

httpClient.interceptors.response.use(
  response => {    
    eventBus.$emit('request_end', response);
    return response;
  },
  error => {    
    eventBus.$emit('request_error', error);
    return Promise.reject(error);
  }
);

export default httpClient;