import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend, configure, setInteractionMode } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

Object.keys(rules).forEach(rule => {
    extend(rule, {
      ...rules[rule],
      message: messages[rule]
    });
  });

configure({
  classes: {      
    invalid: 'border-red',
  }
});

setInteractionMode('passive');

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);