<template>
  <div id="app" class="min-h-screen">
    <CaptureErrorSnackbar>
      <router-view></router-view>
    </CaptureErrorSnackbar>
    <UiSnackbarContainer :duration="6000" />
  </div>
</template>

<script>
import CaptureErrorSnackbar from "./components/CaptureErrorSnackbar";

export default {
  name: 'App',
  components: {
    CaptureErrorSnackbar
  },
  mounted: function()
  {
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
  },
  methods: {
    scrollFix: function(hashbang)
    {
      location.hash = hashbang;
    }
  }  
}
</script>

<style src="./assets/tailwind.css">
