<template>
    <div class="fixed bottom-0 overflow-hidden z-50">
        <ui-snackbar
            :key="index"
            :message="snackbar.message"
            :background-color="snackbar.backgroundColor"

            @click="onClick(snackbar)"
            @hide="onHide(snackbar, index)"
            @show="onShow(snackbar)"

            v-for="(snackbar, index) in queue"
            v-show="snackbar.show"
        >
        </ui-snackbar>
    </div>
</template>

<script>
import Snackbar from '../plugins/SnackbarPlugin.js';
import UiSnackbar from './UiSnackbar';

export default {
    props: {
        queueSnackbars: {
            type: Boolean,
            default: false
        },
        duration: {
            type: Number,
            default: 5000
        },
    },
    data() {
        return {
            queue: [],
            snackbarTimeout: null
        };
    },
    beforeDestroy() {
        this.resetTimeout();
    },
    methods: {
        createSnackbar(snackbar) {
            snackbar.show = false;
            snackbar.duration = this.duration;
            this.queue.push(snackbar);
            if (this.queue.length === 1) {
                return this.showNextSnackbar();
            } else if (!this.queueSnackbars) {
                this.queue[0].show = false;
            }
        },
        showNextSnackbar() {
            if (this.queue.length === 0) {
                return;
            }
            this.queue[0].show = true;
        },
        onShow(snackbar) {
            if (this.queue.indexOf(snackbar) !== 0) {
                return;
            }
            this.snackbarTimeout = setTimeout(() => {
                this.queue[0].show = false;
            }, snackbar.duration);
        },
        onHide(snackbar, index) {
            this.resetTimeout();
            if (this.queueSnackbars || this.queue.length === 1) {
                this.queue.splice(index, 1);
            } else {
                this.queue.splice(index, this.queue.length - 1);
            }
            this.showNextSnackbar();
        },
        onClick(snackbar) {
            snackbar.show = false;
        },
        resetTimeout() {
            clearTimeout(this.snackbarTimeout);
            this.snackbarTimeout = null;
        }
    },
    components: {
        UiSnackbar
    },
    beforeMount() {
        Snackbar.EventBus.$on('show', (params) => {
            this.createSnackbar(params);
        })
    }    
};
</script>